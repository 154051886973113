import { useContext, useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate } from "react-router-dom-v5-compat";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Collapse } from "react-collapse";

import api, { END_POINTS } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { capitalizeWords, sortArray } from "../../helpers";
import {
  Flex,
  PageTemplate,
  Typography,
  ReviseLoading,
} from "../../components";

import { THandleSubjects, TStudiedData, TSubjectData } from "./types";
import * as S from "./styles";
import { ERoutesPath } from "../../routes";
import { sortFormattedData } from "./helper";

export const SubjectsStudiedPage = () => {
  const { user, isPaidActive, isUserLogged } = useContext(AuthContext);
  const navigate = useNavigate();

  const [coursesSubjects, setCoursesSubjects] = useState<Array<TStudiedData>>(
    []
  );
  const [subjectsStudied, setSubjectsStudied] = useState<TStudiedData>({});
  const [activeCourseOpen, setActiveCourseOpen] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubjectsStudied, setIsLoadingSubjectsStudied] =
    useState<boolean>(false);

  const idAccount = user.id_user;

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) navigate(ERoutesPath.ROOT);
    else handleGetQuestions();
  }, []);

  const getStudiesSubjects = async () => {
    try {
      const studied = await api.get(
        `${END_POINTS.studiesSubjectsByUser}/${idAccount}`
      );
      setSubjectsStudied(studied.data || {});
    } catch (error) {
      console.error("Error fetching studied subjects:", error);
      setSubjectsStudied({});
    }
  };

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);
      const coursesAndQuestions = await api.get<TSubjectData>(
        END_POINTS.coursesByQuestion
      );

      const formattedData = coursesAndQuestions.data.map((item) => ({
        [item.course]: item.matters.map((matter) => matter.matter),
      }));
      setCoursesSubjects(sortFormattedData(formattedData));

      await getStudiesSubjects();
    } catch {
      alert("Desculpe. Tente novamente mais tarde");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSubject = async ({
    isAdding,
    subjects,
    course,
  }: THandleSubjects) => {
    const body = {
      subjects: subjects,
      course: course,
    };

    try {
      setIsLoadingSubjectsStudied(true);

      if (isAdding) {
        await api.post(`${END_POINTS.addStudiedSubject}/${idAccount}`, body);
      } else {
        await api.patch(
          `${END_POINTS.removeStudiedSubject}/${idAccount}`,
          body
        );
      }

      await getStudiesSubjects();
    } catch {
      alert("Desculpe. Tente novamente mais tarde.");
    } finally {
      setIsLoadingSubjectsStudied(false);
    }
  };

  const handleMarkAll = async (course: string) => {
    const courseSubjects = coursesSubjects.find(
      (courseSubject) => Object.keys(courseSubject)[0] === course
    );
    const subjects = courseSubjects && courseSubjects[course];

    const isAllSelected =
      subjectsStudied &&
      subjects &&
      subjects.every((subject) => subjectsStudied[course]?.includes(subject));

    if (isAllSelected) {
      await handleChangeSubject({ isAdding: false, subjects, course });
    } else {
      await handleChangeSubject({ isAdding: true, subjects, course });
    }
  };

  const subStudiedBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
      route: ERoutesPath.REVISOES,
    },
    { label: "Assuntos estudados" },
  ];

  return (
    <PageTemplate
      title="Assuntos estudados"
      breadcrumbsItems={subStudiedBreadcrumb}
    >
      {isLoading ? (
        <ReviseLoading />
      ) : (
        <Flex direction="column" gap={8} width="100%">
          {coursesSubjects.map((courseSubject, index) => {
            const course = Object.keys(courseSubject)[0];
            const matters = sortArray(courseSubject[course]);

            const isAllSelected =
              subjectsStudied &&
              subjectsStudied[course] &&
              matters.every((matter) =>
                subjectsStudied[course]?.includes(matter)
              );

            return (
              <S.SubjectItem direction="column" key={course}>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  onClick={() =>
                    setActiveCourseOpen(
                      activeCourseOpen === index ? undefined : index
                    )
                  }
                  style={{ cursor: "pointer", padding: "24px" }}
                >
                  <Typography weight={600} color="#222B45">
                    {capitalizeWords(course)}
                  </Typography>
                  {activeCourseOpen === index ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Flex>

                <Collapse isOpened={activeCourseOpen === index}>
                  <S.Divider />
                  {isLoadingSubjectsStudied ? (
                    <ReviseLoading />
                  ) : (
                    <>
                      <Flex
                        style={{
                          margin: "8px 24px",

                          borderBottom: "1px solid #e6e9fe",
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox checked={isAllSelected} />}
                          label={
                            isAllSelected
                              ? "Desmarcar todas"
                              : "Selecionar todos"
                          }
                          onClick={() => handleMarkAll(course)}
                        />
                      </Flex>
                      <S.MattersContainer>
                        {matters.map((matter) => {
                          const isStudied =
                            subjectsStudied &&
                            subjectsStudied[course]?.includes(matter);

                          return (
                            <FormControlLabel
                              key={`${course}-${matter}`}
                              control={
                                <Checkbox
                                  checked={isStudied}
                                  onChange={(e) =>
                                    handleChangeSubject({
                                      isAdding: e.target.checked,
                                      subjects: [matter],
                                      course,
                                    })
                                  }
                                />
                              }
                              label={capitalizeWords(matter)}
                            />
                          );
                        })}
                      </S.MattersContainer>
                    </>
                  )}
                </Collapse>
              </S.SubjectItem>
            );
          })}
        </Flex>
      )}
    </PageTemplate>
  );
};
